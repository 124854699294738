class periodComponent extends Component {

    static name() {
        return "period-component";
    }

    static componentName() {
        return "period-component";
    }

    getProps() {
        return {
            'param': {
                type: Object,
                default: {},
            },
            'field': String,
            'value': String,
            "description": Object,
            'callback': Function
        };
    }

    data() {
        return {
            date: moment(),
            from_id: this.getUI(),
            to_id: this.getUI(),
            lastx_id: this.getUI(),
        };
    }

    getComputed() {
        return {
            field: function () {
                return this.description.field;
            },
            last_x: function () {
                return this.field + "_lastXcount_";
            },
            selector: function () {
                return this.field + "_selector_";
            },
            from: function () {
                return this.field + "_from_";
            },
            to: function () {
                return this.field + "_to_";
            },
            label: function () {
                return this.description.label ? this.description.label : this.description.field;
            },
            last_x_label: function () {
                return `${this.tr("X Value")} - ${this.tr(this.label)}`;
            },
            from_label: function () {
                return `${this.tr("From")} - ${this.tr(this.label)}`;
            },
            to_label: function () {
                return `${this.tr("To")} - ${this.tr(this.label)}`;
            },
            customClass: function () {
                var classes = "oo-select-field ";
                if (this.hide_days && this.hide_lastX) {
                    classes += " col-12";
                } else if (this.hide_days && !this.hide_lastX) {
                    classes += " col-8"
                } else if (!this.hide_days && this.hide_lastX) {
                    classes += " col-4"
                } else {
                    classes += " col-4"
                }
                return classes;
            }
        };
    }

    getMethods() {
        return {
            setDaySelector: function () {

                if ((this.record[this.from] || this.record[this.to]) && !this.record[this.selector]) {

                    this.$nextTick(() => {
                        this.record[this.selector] = "9999"
                    })

                }

            },
            change_selector: function (value) {
                if (value && value.startsWith("LASTX")) {
                    this.$nextTick(() => {
                        this.$refs.input_last.focus()
                        Materialize.updateTextFields()
                    });
                }
            }
        };
    }


    getTemplate() {
        return `<div> 
                 <>                  
                 <input @input="setDaySelector()" type="date" class="col s4" :label="from_label" v-model="param[from]"></input>
                 <input @input="setDaySelector()" type="date" class="col s4" :label="to_label" v-model="param[to]"></input>
              </div>`;
    }
}

periodComponent.registerComponent();

